<template>
    <v-card width="100%">
        <v-card-title>결과 이미지 관리</v-card-title>
        <v-card-actions>
            <v-select :items="trainings"
                      item-value="id" item-text="name"
                      v-model="currentTraining"
                      outlined
                      return-object
                      hide-details
                      label="교육과정"
                      @change="$router.push(`/manage/result/image/${currentTraining.id}`)"
            />
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
            <v-layout wrap>
                <div class="thumb"
                     v-for="(result, i) in results"
                     @click="selectResult(result)"
                >
                    <img :src="`https://my-weekly.s3.ap-northeast-2.amazonaws.com/results/icon_result_${result.result_num}.png`" />
                    <div class="name">{{ result.type }}</div>
                </div>
            </v-layout>
            <v-divider></v-divider>
	        <v-layout class="preview" v-if="currentResult" align-start >
                <v-card class="image">
                    <v-card-title>이미지</v-card-title>
                    <v-card-text>
                        <img :src="currentResult.image" />
                    </v-card-text>
                </v-card>
                <v-card class="dn-image">
                    <v-card-title>다운로드 이미지</v-card-title>
                    <v-card-text>
                        <img :src="currentResult.dn_image" />
                    </v-card-text>
                </v-card>
		        <v-card class="form">
			        <v-card-title>
                        이미지 파일 변경
                    </v-card-title>
                    <v-divider class="ma-0"></v-divider>
                    <v-card-text>
                        <v-file-input
                                v-model="imageFile"
                                class=""
                                label="이미지 파일"
                                truncate-length="40"
                                show-size
                                accept="image/*"
                                @focus="type = 'image'"
                                @change="uploadImage"
                        ></v-file-input>

                        <v-file-input
                                v-model="dnImageFile"
                                class=""
                                label="다운로드 이미지 파일"
                                truncate-length="40"
                                show-size
                                accept="image/*"
                                @focus="type = 'dn-image'"
                                @change="uploadImage"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="kb-primary" @click="revert()">기본이미지로 초기화</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="kb-primary" :disabled="!changed" @click="save()">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
	data() {
		return {
			trainings: [],
            currentTraining: {},
			results: [],
            apiUrl: process.env.VUE_APP_SERVER_HOST,
            imageFile: null,
            dnImageFile: null,
            currentResult: null,
            type: '',
            changed: false,
		}
	},
    watch: {
		$route(to, from) {
			const id = to.params.id
            this.trainings.forEach(training => {
            	if (training.id === id) {
            		this.currentTraining = training
                }
            })
            this.getList()
        },

    },
    filters: {
		lpad(val) {
		    if (!val) return ''

            if (val.toString().length < 2) {
                val = '0' + val
            }

            return val
        }
    },
	mounted() {
        this.currentTraining.id = this.$route.params.id || this.$cookie.get('training_id')
		this.getTrainings()
	},
    methods: {
		getTrainings() {
			this.axios.get(`${this.apiUrl}/api/admin/training`).then(response => {
				if (response.data.code === '0000') {
					this.trainings = response.data.result

                    if (this.trainings.length > 0) {
                    	if (this.currentTraining.id) {
                    		this.trainings.forEach(training => {
                    			if (training.id == this.currentTraining.id) {
                    				this.currentTraining = training
                                }
                            })
                        } else {
		                    this.currentTraining = this.trainings[0]
	                    }
                    }
					this.getList()
				}
			})
		},
		getList() {
		    const self = this
			const params = {
				'training_id': this.currentTraining.id,
            }
			this.axios.get(`${this.apiUrl}/api/admin/result/items`, {params: params}).then(response => {
				if (response.data.code === '0000') {
				    self.results = []
					Object.keys(response.data.result).forEach((key, index) => {
						self.results.push(response.data.result[key])
                    })
                }
			})
		},
        selectResult(result) {
			this.currentResult = result
            this.imageFile = this.dnImageFile = null
            this.changed = false
        },
        uploadImage() {

            const self = this

            if (this.type === 'image' && !this.imageFile || this.type === 'dn-image' && !this.dnImageFile) return

            const formData = new FormData()
            formData.append('file', this.type === 'image' ? this.imageFile : this.dnImageFile)

            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item/image`, formData, options).then(response => {
                if (self.type === 'image') {
                    self.currentResult.image = response.data.result.url
                } else if (self.type === 'dn-image') {
                    self.currentResult.dn_image = response.data.result.url
                }

                self.changed = true
            })
        },

        revert() {

            const params = {
            	id: this.currentResult.id,
                training_id: this.currentTraining.id,
	            result_num: this.currentResult.result_num,
            }

            this.axios.post(`${this.apiUrl}/api/admin/results/revert`, params).then(response => {
                if (response.data.code === '0000') {

                    // this.getList()
                    const index = this.results.indexOf(this.currentResult)
                    console.log(index)
                    console.log(response.data.result)
                    this.results[index] = response.data.result
                    this.currentResult = this.results[index]
                }
            })
        },

        save() {

		    this.currentResult.training_id = this.currentTraining.id

            this.axios.post(`${this.apiUrl}/api/admin/results`, this.currentResult).then(response => {
                if (response.data.code === '0000') {

                    // this.getList()
                    const index = this.results.indexOf(this.currentResult)
                    console.log(index)
                    this.results[index] = response.data.result
                    this.currentResult = this.results[index]
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    img { max-width: 100%; }

    .thumb {
        /*background: #efefef;*/
        border-radius: 10px;
	    margin: 3px;
	    width: 150px;
        cursor: pointer;
        position: relative;

        .name {
            font-family: GmarketSans, sans-serif;
            font-weight: 600;
            font-size: 16px;;
            text-align: center;
	        position: absolute;
            bottom: 0;
            left: 0;
	        right: 0;
            border-radius: 50%;
            padding-top: 75%;
            padding-bottom: 10px;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 50%);
            color: #fff;
            height: 100%;
        }
    }

    .preview {
        img {
            border: 1px solid #ccc;
        }
        .image {
            width: 30%;
        }
        .dn-image {
            margin-left: 20px;
            width: 30%;
        }

        .form {
            margin-left: 20px;
            width: 30%;
        }
    }
</style>
