<template>
    <v-card width="100%">
        <v-card-text id="chartHolder" class="pb-16">
            <div ref="chart" class="chart"></div>
            <v-layout class="legend font-weight-bold" align-center justify-center>
                [전체 과정 결과]
                <img src="/images/icon_chart_total.png" class="ml-5 mr-2" /> 전체 인원 : {{ total }}
                <img src="/images/icon_chart_completed.png" class="ml-5 mr-2" /> 완료 인원 : {{ completed }}
                <img src="/images/icon_chart_incompleted.png" class="ml-5 mr-2" /> 미완료 인원 : {{ incompleted }}
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="downloadResultChart()">
	            과정 결과 이미지 다운로드
            </v-btn>
            <v-btn @click="downloadResultExcel()">
                과정 결과 엑셀 다운로드
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                v-model="selected"
                item-key="id"
                :headers="headers"
                :items="trainings"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                :loading="isLoading"
                loading-text="데이터 조회 중.. 잠시만 기다리세요."
                hide-default-footer
                @page-count="pageCount = $event"
            >
            </v-data-table>
            <v-divider></v-divider>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
            </div>
        </v-card-text>

    </v-card>
</template>

<script>
    import domtoimage from 'dom-to-image'

    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    am4core.useTheme(am4themes_animated);

    export default {
    	components: {
            domtoimage,
        },
	    data() {
		    return {
		    	page: 1,
                pageCount: 0,
			    itemsPerPage: 100,
			    headers: [
                    {text: '#', sortable: true, value: 'id'},
				    {text: '교육명', sortable: true, value: 'name'},
				    {text: '진행일', sortable: true, value: 'date' },
				    {text: '인원', sortable: true, value: 'trainees_count' },
				    {text: '교육과정 완료 인원', sortable: true, value: 'completed_count' },
				    {text: '교육과정 미 완료 인원', sortable: true, value: 'incompleted_count' },
			    ],
                isLoading: true,
                trainings: [],
                selected: [],
                showDialog: false,
                file: null,
                chart: null,
                total: 0,
                completed: 0,
                incompleted: 0,
		    }
	    },
        watch: {
            showDialog() {
                this.file = null
            }
        },
        mounted() {

            // this.chart.legend = new am4charts.Legend()


            this.getList()
        },
        methods: {
            getList() {
                this.isLoading = true
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/results`).then(response => {
                    if (response.data.code === '0000') {
                        this.trainings = response.data.result
                        this.isLoading = false

                        this.selected = this.trainings

                        const list = {
                            'ISTJ': {name: '오차없는 메트로놈', count: 0},
                            'ISFJ': {name: '우직한 주춧돌', count: 0},
                            'INFJ': {name: '우주를 담은 수정구슬', count: 0},
                            'INTJ': {name: '혼자서도 잘하는 단호박', count: 0},
                            'ISTP': {name: '칠전팔기 레이서', count: 0},
                            'ISFP': {name: '겸손한 마시멜로', count: 0},
                            'INFP': {name: '수줍은 불꽃', count: 0},
                            'INTP': {name: '산골짜기 문학소년', count: 0},
                            'ESTP': {name: '모험을 떠나는 콜럼버스', count: 0},
                            'ESFP': {name: '명랑한 행복 배달부', count: 0},
                            'ENFP': {name: '호기심 많은 앵무새', count: 0},
                            'ENTP': {name: '다재다능한 에너지몬스터', count: 0},
                            'ESTJ': {name: '흐트러짐 없는 일잘러', count: 0},
                            'ESFJ': {name: '따스한 멘탈 충전소', count: 0},
                            'ENFJ': {name: '꺼지지 않는 응원봉', count: 0},
                            'ENTJ': {name: '악단을 이끄는 마에스트로', count: 0},
                        }


                        this.trainings.forEach(training => {
                            this.total += training.trainees_count

                            this.incompleted += training.incompleted_count

                            let completed = 0

                            training.completed.forEach(item => {
                                completed += item.cnt
                                list[item.result].count += item.cnt
                            })
	                        training.completed_count = completed
                            this.completed += completed
                        })

	                    const chartData = []
	                    Object.keys(list).forEach(key => {
	                        chartData.push(list[key])
                        })

	                    // this.chart.data = chartData
	                    this.drawChart(chartData)
                    }
                })
            },
	        drawChart(chartData) {

                let min = 0, max = 0, total = 0, cnt = 0
                chartData.forEach(data => {
                    if (min === -1 || (min > 0 && min > data.count)) {
                	    min = data.count
                    }
                	if (max < data.count) {
                	    max = data.count
                    }

                	total += data.count
                    cnt++
                })
                let avg = total / cnt

                const chart = am4core.create("chart", am4charts.XYChart);
                chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                chart.padding(40, 40, 40, 40);

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.dataFields.category = "name";
                categoryAxis.renderer.minGridDistance = 60;
                categoryAxis.fontSize = 11;
                categoryAxis.renderer.labels.template.rotation = -30;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.max = max;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.minGridDistance = 30;
                valueAxis.fontSize = 11;

                // axis break
                if (max > avg * 5 && max - avg > 10) {

                    let axisBreak = valueAxis.axisBreaks.create();
                    axisBreak.startValue = Math.round(max - (max - avg) * 9 / 10) ;
                    axisBreak.endValue = Math.round(max - (max - avg) / 10)
                    //axisBreak.breakSize = 0.005;

                    // fixed axis break
                    let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min);
                    axisBreak.breakSize = 0.05 * (1 - d) / d; // 0.05 means that the break will take 5% of the total value axis height

                    // make break expand on hover
                    let hoverState = axisBreak.states.create("hover");
                    hoverState.properties.breakSize = 1;
                    hoverState.properties.opacity = 0.1;
                    hoverState.transitionDuration = 1500;

                    axisBreak.defaultState.transitionDuration = 1000;
                }

                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.categoryX = "name";
                series.dataFields.valueY = "count";
                series.columns.template.tooltipText = "{valueY.value}";
                series.columns.template.tooltipY = 0;
                series.columns.template.strokeOpacity = 0;

                let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.verticalCenter = "bottom";
                labelBullet.label.dy = 15;
                labelBullet.label.fontSize = 10;
                labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

                // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                series.columns.template.adapter.add("fill", function(fill, target) {
                    return chart.colors.getIndex(target.dataItem.index);
                });

                chart.data = chartData

            },
            editItem(item, index) {
                this.$router.push(`/training/${this.training.id}/trainee/${item.id}/form`)
            },
            deleteAll() {
                const self = this

                if (!this.selected.length) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 교육생이 없습니다.',
                    })
                    return
                }
                this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `선택된 모든 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{


                        const ids = []
                        this.selected.forEach(item => {
                            ids.push(item.id)
                        })

                        self.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },
	    	deleteItem(item, index) {
                const self = this

	    		this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `[${item.name}] 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{

                        const ids = [ item.id ]

                        self.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },

            moveTo(path) {
	    		this.$eventBus.$emit('MOVE_TO', path)
            },

            downloadTemplate() {
                location.href=`${process.env.VUE_APP_SERVER_HOST}/download/template`
            },
            upload() {
                const self = this

                if (!this.file) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 파일이 없습니다.'
                    })
                    return
                }

                const formData = new FormData()
                formData.append('file', this.file)
                formData.append('id', this.training.id)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`, formData, options).then(response => {
                    self.showDialog = false
                    self.getList()
                })
            },

            downloadResultChart() {
                domtoimage.toJpeg(document.getElementById('chartHolder'), { quality: 0.95 })
                        .then(function (dataUrl) {
                            const link = document.createElement('a');
                            link.download = `전체과정_결과차트_${moment().format('YYYY-MM-DD')}.jpeg`;
                            link.href = dataUrl;
                            link.click();
                        });

            },
            downloadResultExcel() {
                this.axios({
                    url: `${process.env.VUE_APP_SERVER_HOST}/api/admin/result/excel`,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `전체과정_결과${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chart { width: 100%; height: 30vw; }
    .list_title { height: 64px;}

    #chartHolder {
	    background-color: #ffffff;
    }

    .legend { margin-top: -30px; width: 98%; background-color: #ffffff; height: 30px;}
</style>
