<template>
    <v-text-field v-model="pickedColor" hide-details class="ma-0 pa-0" outlined :label="label">
        <template v-slot:append>
            <v-menu v-model="menu" bottom nudge-bottom="35" nudge-left="" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <div class="btn-picker" v-on="on" :style="{ borderRadius: menu ? '50%' : '4px'}">
                        <div class="alpha"></div>
                        <div :style="swatchStyle" />
                    </div>
                </template>
                <v-card>
                    <v-card-text class="pa-0">
                        <color-picker
                                theme="light"
                                :color="pickedColor"
                                @changeColor="changeColor"
                        />
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
	import colorPicker from '@caohenghu/vue-colorpicker'

    export default {
		name: 'ColorPicker',
		components: {
			colorPicker,
        },
        data() {
			return {
				menu: false,
                pickedColor: '',
            }
        },
        props: [
        	'label',
        	'color',
        ],
        computed: {
	        swatchStyle() {
		        const { pickedColor, menu } = this
		        return {
			        backgroundColor: pickedColor,
                    position: 'absolute',
                    left: 0,
                    top: 0,
		        }
	        }

        },
        watch: {
			pickedColor(val) {
				this.$emit('updated', val)
			}

        },
        mounted() {
			if (this.color) {
				this.pickedColor = this.color
            }
        },
	    methods: {
	        changeColor(color) {
		        const { r, g, b, a } = color.rgba
		        this.pickedColor = `rgba(${r}, ${g}, ${b}, ${a})`
	        },
        }
    }
</script>

<style lang="scss">
    .v-application ul, .v-application ol {
        padding-left: 0 !important;
    }
</style>

<style lang="scss" scoped>
    .hu-color-picker {
        width: 218px !important;

    }
    .btn-picker {
        cursor: pointer;
        height: 30px;
        width: 30px;
        border: 1px solid #ccc;
        transition: border-radius 200ms ease-in-out;
        position: relative;
        overflow: hidden;

        &>div {
            width: 100%;
            height: 100%;
        }
    }
    .alpha {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJ0lEQVQoU2M8c/X2fwYkYKylgsxlYKSDgv///6O44ey1O6huoL0CAJgaKeXe+C99AAAAAElFTkSuQmCC');
    }
</style>
