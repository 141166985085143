<template>
            <div class="editor mr-10">
                <div class="topnav">
                    <img src="/images/topnav.png" />
                </div>
                <div class="editor-body">
                    <div class="message-popup">
                        <div class="message-title">
                            MESSAGE
                        </div>
                        <div class="message">
                            <img src="/images/icon_user.png" />
                            <textarea v-model="itemData.message"
                                      placeholder="텍스트를 입력하세요."
                                      @keydown="resizeBox($event.target)"
                                      @keyup="resizeBox($event.target)"
                                      @focus="$emit('click:clear')"
                            />
                        </div>
                        <div class="message-buttons">
                            <div class="item" v-for="(answer, index) in itemData.item_answers">
                                <textarea v-model="answer.text"
                                          placeholder="텍스트를 입력하세요."
                                          @keydown="resizeBox($event.target)"
                                          @keyup="resizeBox($event.target)"
                                          @focus="typeForm(answer)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bottom-message"
                         :style="{background: itemData.text_decorations.background}"
                    >
                        <textarea v-model="itemData.text"
                                  placeholder="텍스트를 입력하세요."
                                  :style="{color: itemData.text_decorations.color, textAlign: itemData.text_decorations.align}"
                                  @keydown="resizeBox($event.target)"
                                  @keyup="resizeBox($event.target)"
                                  @focus="textForm(itemData)"
                        />
                    </div>

                </div>
            </div>
</template>

<script>
export default {
	components: {
    },
	data() {
		return {
			form: '',
            color: '#efefef',
            itemData: {
	            text_decorations: {background: '', color: ''},
            },
			currentItem: {},
        }
    },

    props: [
    	'item'
    ],

    watch: {
		item(val) {
			if (val) {
				if (val.text_decorations && typeof val.text_decorations === 'string') {
					val.text_decorations = JSON.parse(val.text_decorations)
				} else {
					val.text_decorations = { background: '', color: ''}
				}

				if (!val.item_answers || val.item_answers.length === 0) {
					val.item_answers = [
                        {text: ''},
						{text: ''},
					]
				}
				this.itemData = val

				setTimeout(_ => {
					$('textarea').each((idx, el)=> {
						this.resizeBox(el)
					})
				})
			}
		}
    },

	mounted() {
		if (this.item) {
			if (this.item.text_decorations && typeof this.item.text_decorations === 'string') {
				this.item.text_decorations = JSON.parse(this.item.text_decorations)
			} else {
				this.item.text_decorations = { background: '', color: ''}
			}

			if (!this.item.item_answers || this.item.item_answers.length === 0) {
				this.item.item_answers = [
					{text: ''},
					{text: ''},
				]
			}
			this.itemData = this.item

			setTimeout(_ => {
				$('textarea').each((idx, el)=> {
					this.resizeBox(el)
				})
			})

		}
	},

	methods: {
		resizeBox(el) {
			el.style.scrollTop = 0
			el.style.height = 'auto'
			el.style.height = (el.scrollHeight) + 'px'
		},
		backgroundForm() {
	        this.$emit('click:background')
        },
		imageForm(itemData) {
			this.$emit('click:image', itemData)
		},
		typeForm(itemData) {
			this.$emit('click:type', itemData)
		},
		textForm(itemData) {
            this.$emit('click:text', itemData)
        },
        addItem(index) {
			this.itemData.item_answers.splice(index + 1, 0, { image: ''})
        },
        confirmDelete(index) {
			this.$store.commit('showDialog', {
				type: 'confirm',
                message: '선택지를 삭제하시겠습니까?',
                okCb: _ => {
	                this.itemData.item_answers.splice(index, 1)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.editor {
    width: 360px;
    height: 640px;
    border: 1px solid #ccc;
    position: relative;

    .spliter, .left, .right {
        pointer-events: none;
    }

    .topnav {
        width: 100%;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 50;

        img {
            max-width: 100%;
        }
    }

    .editor-body {
        width: 100%; height: 100%;
        overflow: hidden;
        background: #f2f3f7;

        .message-popup{
            position: absolute;
            left: 37px;
            right: 39px;
            top: 124px;
            border-radius: 12.5px;
            overflow: hidden;
            box-shadow: 10px 10px 15px 0 rgba(15, 41, 107, 0.12);
            border: solid 0.5px rgba(255, 255, 255, 0.5);
            background-image: linear-gradient(311deg, #ffffff 99%, #fcfcfd 58%, #f2f3f7 14%);

            .message-title {
                height: 32px;
                background-color: #1985fb;
                color: #ffffff;
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .message {
                font-size: 16px;;
                min-height: 103px;
                display: inline-flex;
                align-items: center;
                padding: 15px 0;

                img {
                    width: 34.5px;
                    height: 34.5px;
                    margin-left: 18.5px;
                    margin-right: 11px;
                }

                textarea {
                    color: #2f2f2f;
                    font-weight: 500;
                    overflow-x: hidden;
                }
            }

            .message-buttons {
                border-top: solid 0.5px #c3c3c3;
                display: flex;

                .item {
                    flex-grow: 1;
                    justify-items: center;

                    textarea {
                        color: #1985fb;
                        font-size: 16px;;
                        font-weight: 500;
                        text-align: center;
                    }

                    &+.item {
                        border-left: solid 0.5px #c3c3c3;
                    }
                }
            }
        }

        .bottom-message {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 256px;
            padding: 31.4px 40px 24.5px;
            background-color: rgba(39,39,39,.86);

            textarea {
                font-size: 19px;
                font-weight: 500;
                color: #ffffff;
                line-height: 1.42;
            }
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
            padding: 2px;
            overflow-y: hidden;
            pointer-events: auto;
            margin: -1px;
            border: 1px dashed rgba(128,128,128,.5);
        }
        textarea:hover, textarea:focus {
            background: rgba(255,255,255,.2);
            border: 1px solid #ccc;
            margin: -1px;
            border-radius: 5px;
        }

        .items {
            position: absolute;
            top: 249px;
            left: 31.5px;
            right: 31.5px;

            .item {
                width: 135px;
                height: 135px;
                border-radius: 18px;
                box-shadow: 10px 10px 15px 0 rgba(15, 41, 107, 0.12);
                border: solid 0.5px rgba(255, 255, 255, 0.5);
                background-image: linear-gradient(135deg, #e8ebf2, #f2f3f7);
                padding: 14.5px;
                position: relative;

                img {
                    max-width: 100%;
                    height: auto;
                }

                .buttons {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }

                &:nth-child(2n) {
                    margin-left: 25px;
                }

                &:nth-child(n + 3) {
                    margin-top: 25px;
                }

                &:hover {
                    background-image: linear-gradient(137deg, #f5f2ea 3%, #fffef5 99%);

                }
            }
        }
    }
}
</style>
