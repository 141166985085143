<template>
    <v-card width="100%">
        <v-card-title>
            <v-btn icon small class="mr-2" @click="$router.back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <span v-if="item.type === 'intro'">INTRO</span>
            <span v-else>#{{item.sequence | leftPad}}</span>
            <v-spacer></v-spacer>
            <v-btn class="ml-2" color="kb-primary" @click="save">
                <v-icon small class="mr-2">mdi-content-save-outline</v-icon>
                저장
            </v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-wrap">
            <component id="component" ref="component" v-bind:is="currentView" :item="item"
                       @click:text="onClickText"
                       @click:background="onClickBackground"
                       @click:image="onClickImage"
                       @click:clear="clearForm"
                       @click:type="onClickType"
            />
            <div class="mr-10">
                <div class="itemTypeForm mb-5" v-if="item.type !== 'intro'">
                    <v-card elevation="0" width="430" outlined>
                        <v-card-title class="form-title pa-2">문항 유형</v-card-title>
                        <v-divider class="ma-0"></v-divider>
                        <v-card-text>
                            <v-select outlined dense
                                      hide-details
                                      v-model="itemType"
                                      item-text="name" item-value="type"
                                      :items="itemTypes"
                                      @change="onChangeType"
                            />
                        </v-card-text>
                    </v-card>
                </div>
                <div class="actionTypeForm mb-5" v-if="item.type === 'titleleaf'">
                    <v-card elevation="0" width="430" outlined>
                        <v-card-title class="form-title">액션 유형</v-card-title>
                        <v-divider class="ma-0"></v-divider>
                        <v-card-text>
                            <v-select outlined dense
                                      hide-details
                                      v-model="item.action_type"
                                      item-text="name" item-value="type"
                                      :items="actionTypes"
                            />
                        </v-card-text>
                    </v-card>
                </div>
                <transition-group name="fade" mode="in-out">
                    <div key="background" class="backgroundForm">
                        <v-card elevation="0" width="430" outlined>
                            <v-card-title class="form-title">배경 이미지 변경</v-card-title>
                            <v-divider class="ma-0"></v-divider>
                            <v-card-text>
                                <v-file-input
                                        v-model="bg_file"
                                        class=""
                                        label="배경 이미지 파일"
                                        truncate-length="40"
                                        show-size
                                        accept="image/*"
                                        @mousedown="onClickBackground(720,1120)"
                                        @change="uploadImage"
                                ></v-file-input>
                                <div>가로: 720px, 세로: 1120px</div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="kb-primary"
                                       @click="item.background_img = ''"
                                >배경 이미지 삭제</v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                    <div key="image" class="imageForm mt-5" v-if="form === 'image'">
                        <v-card elevation="0" width="430" outlined>
                            <v-card-title class="form-title">이미지 변경</v-card-title>
                            <v-divider class="ma-0"></v-divider>
                            <v-card-text>
                                <v-file-input
                                        v-model="img_file"
                                        class=""
                                        label="이미지 파일"
                                        truncate-length="40"
                                        show-size
                                        accept="image/*"
                                        @change="uploadImage"
                                ></v-file-input>
                                <div v-if="recommendSize" v-text="recommendSize"></div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="kb-primary"
                                       @click="currentItem.image = ''"
                                >이미지 삭제</v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                    <div key="text" class="textForm mt-5" v-if="form === 'text'">
                        <v-card elevation="0" width="430" outlined>
                            <v-card-title class="form-title">텍스트 스타일 변경</v-card-title>
                            <v-card-text>
                                <color-picker
                                        label="배경 색상"
                                        :color="textDecoration.background"
                                        @updated="val => textDecoration.background = val"
                                />
                                <color-picker
                                        class="mt-5"
                                        label="폰트 색상"
                                        :color="textDecoration.color"
                                        @updated="val => textDecoration.color = val"
                                />
	                            <v-sheet class="mt-3">
                                    <div>정렬</div>
                                    <v-btn-toggle v-model="textDecoration.align">
                                        <v-btn value="left">
                                            <v-icon>mdi-format-align-left</v-icon>
                                        </v-btn>

                                        <v-btn value="center">
                                            <v-icon>mdi-format-align-center</v-icon>
                                        </v-btn>

                                        <v-btn value="right">
                                            <v-icon>mdi-format-align-right</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-sheet>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div key="types" class="typesForm mt-5" v-if="enableType">
                        <v-card elevation="0" width="430" outlined>
                            <v-card-title class="form-title">척도</v-card-title>
                            <v-card-text>
                                <div class="btn-answer-type btn-group btn-group-toggle d-block" data-toggle="buttons">
                                    <label class="btn btn-secondary btn-type type-E">
                                        <input type="radio" name="options" value="E">E
                                    </label>
                                    <label class="btn btn-secondary btn-type type-I">
                                        <input type="radio" name="options" value="I">I
                                    </label>
                                </div>
                                <div class="btn-answer-type btn-group btn-group-toggle d-block" data-toggle="buttons">
                                    <label class="btn btn-secondary btn-type type-S">
                                        <input type="radio" name="options" value="S">S
                                    </label>
                                    <label class="btn btn-secondary btn-type type-N">
                                        <input type="radio" name="options" value="N">N
                                    </label>
                                </div>
                                <div class="btn-answer-type btn-group btn-group-toggle d-block" data-toggle="buttons">
                                    <label class="btn btn-secondary btn-type type-T">
                                        <input type="radio" name="options" value="T">T
                                    </label>
                                    <label class="btn btn-secondary btn-type type-F">
                                        <input type="radio" name="options" value="F">F
                                    </label>
                                </div>
                                <div class="btn-answer-type btn-group btn-group-toggle d-block" data-toggle="buttons">
                                    <label class="btn btn-secondary btn-type type-J">
                                        <input type="radio" name="options" value="J">J
                                    </label>
                                    <label class="btn btn-secondary btn-type type-P">
                                        <input type="radio" name="options" value="P">P
                                    </label>
                                </div>
                                <button class="btn btn-clear-types btn-primary"
                                        @click="clearTypes"
                                >사용안함</button>
                            </v-card-text>
                        </v-card>
                    </div>
                </transition-group>
            </div>
            <div v-if="item.type === 'titleleaf'">
                <div class="itemTypeForm mb-5">
                    <v-card elevation="0" width="300" outlined>
                        <v-card-title class="form-title pa-2">효과음</v-card-title>
                        <v-divider class="ma-0"></v-divider>
                        <v-card-text>
	                        <v-layout justify-start>
                                <v-file-input class="file-input"
                                              v-model="audio"
                                              hide-details
                                              hide-input
                                              prepend-icon="mdi-music"
                                              accept=".mp3,.wav,.ogg" @click="onClickFileInput()"
                                              @change="uploadAudio"
                                />
                                <v-text-field label="효과음 파일" v-model="item.audio_name" style="width: 100%;" class="mr-10" readonly @click="onFocus()" />
                            </v-layout>
                            <audio controls :src="`${item.audio_path}`" style="width: 100%" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="kb-primary" :disabled="!item.audio_path" @click="removeAudio()">효과음 삭제</v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
	import domtoimage from 'dom-to-image'

    import ItemSwipe from "./ItemSwipe";
	import ItemIntro from "./ItemIntro";
	import ItemImage from "./ItemImage";
	import ItemText from "./ItemText";
	import ItemKakao from "./ItemKakao";
	import ItemMessenger from "./ItemMessenger";
	import ItemTitleLeaf from "./ItemTitleLeaf";
	import ColorPicker from "../../common/ColorPicker";
export default {
	components: {
		domtoimage,
		ColorPicker,
		ItemSwipe,
        ItemIntro,
		ItemImage,
		ItemText,
        ItemKakao,
        ItemMessenger,
        ItemTitleLeaf,
	},
	data() {
		return {
			form: '',
            color: '#efefef',
            currentView: null,
            item: {},
			currentItem: {},
            textDecoration: {},
            bg_file: null,
            img_file: null,
			audio: null,
			itemType: null,
            itemTypes: [
	            { type: 'titleleaf', name: '간지'},
	            { type: 'swipe', name: '스와이프'},
	            { type: 'image', name: '객관식 이미지'},
	            { type: 'text', name: '객관식 텍스트'},
	            { type: 'kakao', name: '카톡 메시지'},
	            { type: 'messenger', name: '문자 메시지'},
            ],
            actionTypes: [
	            { type: 'button', name: '버튼'},
	            { type: 'swipe', name: '스와이프'},
            ],
            enableType: false,
            recommendSize: '',
			apiUrl: process.env.VUE_APP_SERVER_HOST,
        }
    },
    mounted() {
		const id = this.$route.params.id
	    this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item/${id}`).then(response => {
		    if (response.data.code === '0000') {
			    this.item = response.data.result
                this.itemType = this.item.type
                switch (this.item.type) {
			    	case 'intro': this.currentView = 'ItemIntro'; break;
	                case 'swipe': this.currentView = 'ItemSwipe'; break;
	                case 'image': this.currentView = 'ItemImage'; break;
	                case 'text': this.currentView = 'ItemText'; break;
	                case 'kakao': this.currentView = 'ItemKakao'; break;
	                case 'messenger': this.currentView = 'ItemMessenger'; break;
	                case 'titleleaf': this.currentView = 'ItemTitleLeaf'; break;
                }
		    }
	    })

	    $(document.body).off('click', '.btn-answer-type > label').on('click', '.btn-answer-type > label', e => {
        	const target = e.currentTarget

            $('.btn-answer-type > label').removeClass('active')
            const val = $(target).find('input').val()
            this.currentItem.type = val
        })
    },

    watch: {
    },

	methods: {
		onChangeType() {
			this.$store.commit('showDialog', {
				type: 'confirm',
                message: '문항 유형을 변경하면 입력된 모든 데이터가 삭제됩니다.\n\n정말 문항 유형을 변경하시겠습니까?',
                okCb: _ => {
					this.item.type = this.itemType

	                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item`, this.item).then(response => {
		                if (response.data.code === '0000') {
			                this.$router.go(this.$router.currentRoute)
		                } else {
			                this.$store.commit('showSnackbar', {
				                message: '저장 중 오류가 발생했습니다.',
				                color: 'error',
				                duration: 3000
			                });
		                }
	                })
                }
            })
        },
		resizeBox(event) {
			const target = event.currentTarget

			target.style.scrollTop = 0
			target.style.height = 'auto'
            target.style.height = (target.scrollHeight) + 'px'
        },
        clearTypes() {
			this.currentItem.type = null
            $('.btn-type').removeClass('active').find('input').prop('checked', false)
        },
		onClickText(itemData, withType, isMessage) {
			this.currentItem = itemData
            if (isMessage) {
	            this.textDecoration = itemData.message_decorations
            } else {
	            this.textDecoration = itemData.text_decorations
            }
			this.enableType = false
			this.form = ''
            this.recommendSize = ''
			setTimeout(_ => {
				this.form = 'text'

                if (withType) {
	                this.enableType = true
                }

				if (itemData.type) {
					setTimeout(_ => {
						$(`.type-${itemData.type}`).addClass('active')
                    })
				}
			})
		},
		onClickBackground(width, height) {
			this.currentItem = this.item
			this.bg_file = null;
			this.enableType = false
			this.form = ''
			this.recommendSize = ''

			setTimeout(_ => {
				this.form = 'background'

				if (width && height) {
					this.recommendSize = `가로: ${width}px, 세로: ${height}px`
				}
			})
		},
		onClickImage(itemData, withType, width, height) {
			this.currentItem = itemData
			this.img_file = null;
			this.enableType = false
			this.form = ''
			this.recommendSize = ''

			setTimeout(_ => {
				this.form = 'image'

				if (withType) {
					this.enableType = true
				}

				if (width && height) {
					this.recommendSize = `가로: ${width}px, 세로: ${height}px`
				}

				if (itemData.type) {
					setTimeout(_ => {
						$(`.type-${itemData.type}`).addClass('active')
					})
				}
			})
		},
		onClickType(itemData) {
			this.currentItem = itemData
			this.enableType = false
			this.form = ''
			this.recommendSize = ''

			setTimeout(_ => {
                this.enableType = true

				if (itemData.type) {
					setTimeout(_ => {
						$(`.type-${itemData.type}`).addClass('active')
					})
				}
			})
		},
        clearForm() {
			this.form = ''
	        this.recommendSize = ''
	        this.enableType = false
        },
		uploadImage() {

			const self = this

            if (this.form === 'background' && !this.bg_file || this.form === 'image' && !this.img_file) return

	        const formData = new FormData()
	        formData.append('file', this.form === 'background' ? this.bg_file : this.img_file)

	        const options = {
		        headers: {
			        'Content-Type': 'multipart/form-data'
		        }
	        }

	        this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item/image`, formData, options).then(response => {
	        	if (self.form === 'background') {
			        self.item.background_img = response.data.result.url
		        } else if (self.form === 'image') {
			        self.currentItem.image = response.data.result.url
                }
	        })
        },
        uploadAudio() {

            const self = this

            if (!this.audio) return

            const formData = new FormData()
            formData.append('audio', this.audio)

            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item/audio`, formData, options).then(response => {
                self.item.audio_path = response.data.result.path
                self.item.audio_name = response.data.result.name
                self.item.audio_filename = response.data.result.filename

	            console.log(self.item)
            })
        },
        removeAudio() {
			this.item.audio_path = ''
            this.item.audio_name = ''
            this.item.audio_filename = ''
        },
        async capture() {
            $('textarea,input').attr('placeholder', '')
            $('.buttons').hide();

			// const node = this.$refs.component
            const node = document.getElementById('component')

	        const dataUrl = await domtoimage.toPng(node, {cacheBust: true, imagePlaceholder:`data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`})

            return dataUrl
        },
        async save() {
	        $('.editor .topnav').css('opacity', 1)
	        $('textarea, input').css('border', '0')
	        $('.item .buttons').hide()

	        let completed = true
            // $('.editor-body').find('textarea, input').each((idx, input) => {
            // 	if (!$(input).val()) completed = false
            // })
            // $('.editor-body .item > img').each((idx, img) => {
            //     if (!$(img).attr('src')) completed = false
            // })

	        let snapshot = ''
            if (completed) {
                snapshot = await this.capture().catch(e => {
                })
            }

            this.item.preview = snapshot

	        this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/item`, this.item).then(response => {
	        	if (response.data.code === '0000') {
			        this.$store.commit('showSnackbar', {
				        message: '저장했습니다.',
				        color: 'success',
				        duration: 3000
			        });
			        this.$router.back();
                } else {
			        this.$store.commit('showSnackbar', {
				        message: '저장 중 오류가 발생했습니다.',
				        color: 'error',
				        duration: 3000
			        });
                }
	        })
        },
        onFocus() {
            $('.file-input').find('input').click()
        },
        onClickFileInput() {
            this.audio = null
        },
	}
}
</script>

<style lang="scss" scoped>
    .editor {
        width: 360px;
        height: 640px;
        border: 1px solid #ccc;
    position: relative;

    .spliter, .left, .right {
        pointer-events: none;
    }

    .topnav {
        width: 100%;
        opacity: 0.5;

        img {
            max-width: 100%;
        }
    }

    .editor-body {
        .background:hover {
            opacity: 0.9;
            cursor: pointer;
            border: 1px solid #ccc;
            margin: -1px;
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
            padding: 2px;
            overflow-y: hidden;
            pointer-events: auto;
        }
        textarea:hover, textarea:focus {
            background: rgba(255,255,255,.2);
            border: 1px solid #efefef;
            margin: -1px;
            border-radius: 5px;
        }
        .message {
            position: absolute;
            width: 267px;
            height: 74px;
            top: 129px;
            left: 33px;
            /*background: rgba(255,255,255,.3);*/
            font-size: 16px;

            textarea {
                overflow-x: hidden;
            }
        }

        .spliter {
            position: absolute;
            left: 87px;
            top: 314px;

            img {
                width: 198px;
            }
        }

        .left {
            position: absolute;
            left: 34px;
            top: 262px;

            img {
                width: 49px;
                display: block;
            }

            textarea {
                width: 172px;
                height: 74px;
                font-size: 16px;
                margin-top: 8px;
            }
        }

        .right {
            position: absolute;
            right: 35px;
            top: 412px;
            text-align: right;

            img {
                width: 61px;
            }

            textarea {
                width: 172px;
                height: 74px;
                font-size: 16px;
                margin-top: 8px;
                text-align: right;
            }
        }
    }
}

    .form-title {
        color: #60584c
    }

    .btn-group-toggle {
        margin-bottom: -2px;

        label{
            width: 40px !important;
            background-color: #FF773B!important;
            color: #60584C!important;

            &.active {
                background-color: #60584c !important;
                color: #fff !important;
            }
        }
    }

    .btn-clear-types {
        padding: .375rem 0.59rem !important;
    }

</style>
