<template>
            <div class="editor mr-10">
                <div class="topnav">
                    <img src="/images/topnav.png" />
                </div>
                <div class="editor-body">
                    <div class="background" @click="backgroundForm(720, 1120)">
                        <img :src="itemData.background_img" width="100%" v-if="itemData.background_img" />
                        <div style="width: 100%; height: 100%; background-color: #efefef;" v-else />
                    </div>
                    <div class="message">
                        <textarea v-model="itemData.text"
                                  :style="{color: itemData.text_decorations.color,background: itemData.text_decorations.background,textAlign: itemData.text_decorations.align}"
                                  placeholder="텍스트를 입력하세요."
                                  @keydown="resizeBox($event.target)"
                                  @keyup="resizeBox($event.target)"
                                  @focus="textForm(itemData)"
                            />
                    </div>
                    <div class="spliter">
                        <img src="/images/swipe_splitter.png" />
                    </div>
                    <div class="left">
                        <img src="/images/swipe_left.png" />
                        <div>
                            <textarea v-model="itemData.item_answers[0].text"
                                      :style="{color: itemData.item_answers[0].text_decorations.color,background: itemData.item_answers[0].text_decorations.background, textAlign: itemData.item_answers[0].text_decorations.align}"
                                      placeholder="텍스트를 입력하세요."
                                      @keydown="resizeBox($event.target)"
                                      @keyup="resizeBox($event.target)"
                                      @focus="textForm(itemData.item_answers[0], true)"
                                />
                        </div>
                    </div>
                    <div class="right">
                        <div>
                            <img src="/images/swipe_right.png" />
                        </div>
                        <div>
                            <textarea v-model="itemData.item_answers[1].text"
                                      :style="{color: itemData.item_answers[1].text_decorations.color,background: itemData.item_answers[1].text_decorations.background, textAlign: itemData.item_answers[1].text_decorations.align}"
                                      placeholder="텍스트를 입력하세요."
                                      @keydown="resizeBox($event.target)"
                                      @keyup="resizeBox($event.target)"
                                      @focus="textForm(itemData.item_answers[1], true)"
                            />
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
export default {
	components: {
    },
	data() {
		return {
			form: '',
            color: '#efefef',
            itemData: {
	            text_decorations: {background: '', color: ''},
	            item_answers: [
                    { text_decorations: { background: '', color: ''}},
	                { text_decorations: { background: '', color: ''}}
                ]
            },
			currentItem: {},
        }
    },

    props: [
    	'item'
    ],

    watch: {
		item(val) {
			if (val) {
				if (val.text_decorations && typeof val.text_decorations === 'string') {
					val.text_decorations = JSON.parse(val.text_decorations)
				} else {
					val.text_decorations = { background: '', color: ''}
                }
				if (val.item_answers && val.item_answers.length) {
					val.item_answers.forEach(item => {
						if (item.text_decorations && typeof item.text_decorations === 'string') {
							item.text_decorations = JSON.parse(item.text_decorations)
                        }
                    })
                } else {
					val.item_answers = [
						{ text_decorations: { background: '', color: ''}},
						{ text_decorations: { background: '', color: ''}}
					]
                }
				this.itemData = val

				setTimeout(_ => {
					$('textarea').each((idx, el)=> {
						this.resizeBox(el)
					})
				})
			}
		}
    },

	mounted() {
		if (this.item) {
		    if (this.item.text_decorations && typeof this.item.text_decorations === 'string') {
			    this.item.text_decorations = JSON.parse(this.item.text_decorations)
		    } else {
			    this.item.text_decorations = { background: '', color: ''}
		    }
		    if (this.item.item_answers && this.item.item_answers.length) {
			    this.item.item_answers.forEach(item => {
				    if (item.text_decorations && typeof item.text_decorations === 'string') {
					    item.text_decorations = JSON.parse(item.text_decorations)
				    }
			    })
		    } else {
			    this.item.item_answers = [
				    { text_decorations: { background: '', color: ''}},
				    { text_decorations: { background: '', color: ''}}
			    ]
		    }
		    this.itemData = this.item

            setTimeout(_ => {
            	$('textarea').each((idx, el)=> {
            		this.resizeBox(el)
                })
            })

	    }
    },

	methods: {
		resizeBox(el) {
			el.style.scrollTop = 0
			el.style.height = 'auto'
            el.style.height = (el.scrollHeight) + 'px'
        },
        backgroundForm(width, height) {
	        this.$emit('click:background', width, height)
        },
        textForm(itemData, withType) {
            this.$emit('click:text', itemData, withType)
        },
    }
}
</script>

<style lang="scss" scoped>
.editor {
    width: 360px;
    height: 640px;
    border: 1px solid #ccc;
    position: relative;

    .spliter, .left, .right {
        pointer-events: none;
    }

    .topnav {
        width: 100%;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 50;

        img {
            max-width: 100%;
        }
    }

    .editor-body {
        width: 100%; height: 100%;
        overflow: hidden;

        .background {
            position: absolute;
            top: 87px; left: 0;
            width: 100%;
            height: calc(100% - 87px);
        }
        .background:hover {
            opacity: 0.9;
            cursor: pointer;
            border: 1px solid #ccc;
            margin: -1px;
            width: calc(100% + 2px);
            height: calc(100% - 85px);
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
            padding: 2px;
            overflow-y: hidden;
            pointer-events: auto;
            margin: -1px;
            border: 1px dashed rgba(128,128,128,.5);
        }
        textarea:hover, textarea:focus {
            background: rgba(255,255,255,.2);
            border: 1px solid #ccc;
            margin: -1px;
            border-radius: 5px;
        }
        .message {
            position: absolute;
            width: 267px;
            height: 74px;
            top: 129px;
            left: 33px;
            /*background: rgba(255,255,255,.3);*/
            font-size: 16px;;

            textarea {
                overflow-x: hidden;
            }
        }

        .spliter {
            position: absolute;
            left: 87px;
            top: 314px;

            img {
                width: 198px;
            }
        }

        .left {
            position: absolute;
            left: 34px;
            top: 262px;

            img {
                width: 49px;
                display: block;
            }

            textarea {
                width: 172px;
                height: 74px;
                font-size: 16px;;
                margin-top: 8px;
            }
        }

        .right {
            position: absolute;
            right: 35px;
            top: 412px;
            text-align: right;

            img {
                width: 61px;
            }

            textarea {
                width: 172px;
                height: 74px;
                font-size: 16px;;
                margin-top: 8px;
                text-align: right;
            }
        }
    }
}
</style>
