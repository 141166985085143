import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

export default new Router({
    mode: 'history',
    routes: [
        {
        	path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            component: require('../components/login/Login').default
        },
        {
            path: '/main',
            name: 'Main',
            component: require('../components/main/Main').default,
            children: [
                {
                    path: '/training',
                    name: 'TrainingList',
                    components: {
                        main: require('../components/trainings/TrainingList').default
                    }
                },
                {
                    path: '/training/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TrainingForm').default
                    }
                },
                {
                    path: '/training/:id/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TrainingForm').default
                    }
                },
                {
                    path: '/training/:id/trainees',
                    name: 'TraineeList',
                    components: {
                        main: require('../components/trainings/TraineeList').default
                    }
                },
                {
                    path: '/training/:id/trainee/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TraineeForm').default
                    }
                },
                {
                    path: '/training/:id/trainee/:trainee_id/form',
                    name: 'TraineeForm',
                    components: {
                        main: require('../components/trainings/TraineeForm').default
                    }
                },

                {
                    path: '/training/:id/results',
                    name: 'ResultList',
                    components: {
                        main: require('../components/trainings/ResultList').default
                    }
                },
                {
                    path: '/manage/items',
                    name: 'ItemManage',
                    components: {
                        main: require('../components/manage/items/Item').default
                    }
                },
                {
                    path: '/manage/items/:id',
                    name: 'ItemManage',
                    components: {
                        main: require('../components/manage/items/Item').default
                    }
                },
                {
                    path: '/manage/item/:id',
                    name: 'ItemManageForm',
                    components: {
                        main: require('../components/manage/items/ItemForm').default
                    }
                },
                {
                    path: '/manage/audio',
                    name: 'AudioManage',
                    components: {
                        main: require('../components/manage/Audio').default
                    }
                },
                {
                    path: '/manage/audio/:id',
                    name: 'AudioManage',
                    components: {
                        main: require('../components/manage/Audio').default
                    }
                },
                {
                    path: '/manage/result/image',
                    name: 'ResultImageManage',
                    components: {
                        main: require('../components/manage/ResultImage').default
                    }
                },
                {
                    path: '/manage/result/image/:id',
                    name: 'ResultImageManage',
                    components: {
                        main: require('../components/manage/ResultImage').default
                    }
                },
                {
                    path: '/manage/result/pdf',
                    name: 'ResultPdfManage',
                    components: {
                        main: require('../components/manage/ResultPdf').default
                    }
                },
                {
                    path: '/manage/result/pdf/:id',
                    name: 'ResultPdfManage',
                    components: {
                        main: require('../components/manage/ResultPdf').default
                    }
                },
                {
                    path: '/manage/email/template',
                    name: 'EmailTemplate',
                    components: {
                        main: require('../components/manage/EmailTemplate').default
                    }
                },
                {
                    path: '/results',
                    name: 'TotalResultList',
                    components: {
                        main: require('../components/main/TotalResultList').default
                    }
                },
            ]
        },
    ]
})
