<template>
    <v-card width="100%">
        <v-card-title>전체 교육 목록</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-primary"
                    @click="openCloneDialog()"
            >
                <v-icon left>mdi-plus</v-icon>새 교육 추가
            </button>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="trainings"
                    :items-per-page="itemsPerPage"
                    :page.sync="page"
                    :loading="isLoading"
                    loading-text="데이터 조회 중.. 잠시만 기다리세요."
                    hide-default-footer
                    @page-count="pageCount = $event"
                >
                <template v-slot:item.name="{ item }">
                    <router-link :to="{ path: `/training/${item.id}/trainees`}">{{ item.name }}</router-link>
                </template>
                <template v-slot:item.date="{ item }">
                    {{ item.date | moment('YYYY/MM/DD') }}
                </template>
                <template v-slot:item.start_time="{ item }">
                    {{ moment(moment().format('YYYY-MM-DD ') + item.start_time).format('A hh:mm') }} ~
                    {{ moment(moment().format('YYYY-MM-DD ') + item.end_time).format('A hh:mm') }}
                </template>
                <template v-slot:item.trainees_count="{ item }">
                    {{ item.trainees_count | comma }}명
                </template>
                <template v-slot:item.actions="{ item, index }">
                    <div class="text-center">
                        <v-btn small color="secondary"
                               @click="editItem(item, index)"
                               class="mr-1"
                        >수정</v-btn>
                        <v-btn small color="#C72D1B" style="color: #fff !important;"
                                @click="deleteItem(item, index)"
                               class="mr-1"
                        >삭제</v-btn>
                        <v-btn small color="default"
                               @click="openCloneDialog(item, index)"
                               class="mr-1"
                        >복사</v-btn>
                    </div>
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
            </div>
        </v-card-text>
        <v-dialog v-if="item" v-model="showDialog" width="500" style="overflow-x:hidden">
            <v-card>
                <v-card-title>
                    <div class="headline">
                        교육과정 {{ item.name ? '복사' : '추가'}}</div>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            label="교육과정명"
                            v-model="clone.name"
                            outlined
                            dense
                            required
                            :rules="[v => !!v || '필수항목입니다.']"
                    />
                    <v-text-field
                            label="교육진행일"
                            v-model="clone.date"
                            type="date"
                            outlined
                            dense
                            required
                            :rules="[v => !!v || '필수항목입니다.']"
                    ></v-text-field>
	                <v-layout align-center>
                        <v-text-field
                                label="시작시각"
                                v-model="clone.start_time"
                                type="time"
                                outlined
                                dense
                                required
                                :rules="[v => !!v || '필수항목입니다.']"
                                @change="onChangeStartTime()"
                        ></v-text-field>
                        <p style="font-size: 20px; padding: 0 10px;"> ~ </p>
                        <v-text-field
                                label="종료시각"
                                v-model="clone.end_time"
                                type="time"
                                outlined
                                dense
                                required
                                :rules="[v => !!v || '필수항목입니다.']"
                        ></v-text-field>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="kb-primary" @click.native="cloneItem"
                           :disabled="cloning"
                    >
                        {{ item.name ? '복사' : '추가'}}
                    </v-btn>
                    <v-btn @click.native="showDialog=false">취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                moment: moment,
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                headers: [
                    {text: '#', sortable: true, value: 'id'},
                    {text: '교육과정명', sortable: true, value: 'name'},
                    {text: '진행일', sortable: true, value: 'date'},
				    {text: '진행시간', sortable: true, value: 'start_time'},
				    {text: '인원', sortable: true, value: 'trainees_count'},
				    {text: '교육관리', sortable: false, value: 'actions', align: 'center'},
			    ],
                trainings: [],
                isLoading: true,

                showDialog: false,
                item: null,
                clone: {},
                cloning: false,

		    }
	    },

        filters: {
	    	comma(val) {
			    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		    }
        },
        mounted() {
	    	this.getList()
        },
        methods: {
	    	getList() {
	    		this.isLoading = true
			    this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`).then(response => {
				    if (response.data.code === '0000') {
					    this.trainings = response.data.result
					    this.isLoading = false
				    }
			    })
		    },
	        editItem(item, index) {
	    		this.$router.push(`/training/${item.id}/form`)
            },
	    	deleteItem(item, index) {
	    		this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `[${item.name}] 교육을 삭제하시겠습니까?`,
                    okCb: _ =>{
	    				if (item.trainees_count > 0) {
						    this.$store.commit('hideDialog')

                            setTimeout(_ => {
	                            this.$store.commit('showDialog', {
		                            type: 'confirm',
		                            message: `[${item.name}]에 등록된 교육생이 있습니다.\n등록된 교육생과 결과가 모두 삭제됩니다.\n\n그래도 삭제하시겠습니까?`,
		                            okCb: _ => {
			                            this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${item.id}`).then(response => {
				                            if (response.data.code === '0000') {
					                            this.getList()
				                            }
			                            })
		                            }

	                            })
                            }, 50)
					    } else {
						    this.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${item.id}`).then(response => {
							    if (response.data.code === '0000') {
								    this.getList()
							    }
						    })
					    }
                    }
                })
            },

            openCloneDialog(item, index) {
	    		if (!item) {
	    		    item = {
	    		    	id: 2
                    }
                }
	    	    this.item = item
                this.clone = {}
	    		this.showDialog = true
            },

            onChangeStartTime() {
                // if (this.clone.start_time && !this.clone.end_time) {
                //     this.clone.end_time = this.clone.start_time
                // }
            },

            cloneItem() {
	    		let msg
	    	    if (!this.clone.name) {
                    msg = '교육과정명을 입력하세요.'
                } else
                if (!this.clone.date) {
                    msg = '진행일을 입력하세요.'
                } else
	    	    if (!this.clone.start_time || !this.clone.end_time) {
                    msg = '진행시간을 입력하세요.'
                }

	    	    if (msg) {
                    this.$store.commit("showDialog", {
                        type: 'alert',
	                    message: msg
                    })

                    return
                }

                this.showDialog = false
                this.cloning = true

	    	    this.clone.id = this.item.id
                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/clone`, this.clone).then(response => {
                    this.cloning = false
                    if (response.data.code === '0000') {
                    	this.getList()
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
