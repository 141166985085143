<template>
    <v-card width="100%">
        <v-card-title class="list_title">
            <v-btn icon small class="mr-2" @click="$router.back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ training.name}}
        </v-card-title>
        <v-card-actions>
            <buttons :training="training" />
        </v-card-actions>
        <v-card-text id="chartHolder" class="pb-16">
            <div ref="chart" class="chart"></div>
            <v-layout class="legend font-weight-bold" align-center justify-center>
                [{{ training.name}}]
                <img src="/images/icon_chart_total.png" class="ml-5 mr-2" /> 전체 인원 : {{ total }}
                <img src="/images/icon_chart_completed.png" class="ml-5 mr-2" /> 완료 인원 : {{ completed }}
                <img src="/images/icon_chart_incompleted.png" class="ml-5 mr-2" /> 미완료 인원 : {{ incompleted }}
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="downloadResultChart()">
	            과정 결과 이미지 다운로드
            </v-btn>
            <v-btn @click="downloadResultExcel()">
                과정 결과 엑셀 다운로드
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="이름, 직원번호, 직급, 소속, 이메일"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-actions>
        <v-card-text>
            <v-data-table
                v-model="selected"
                item-key="id"
                :headers="headers"
                :items="trainees"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                :loading="isLoading"
                :search="search"
                loading-text="데이터 조회 중.. 잠시만 기다리세요."
                hide-default-footer
                @page-count="pageCount = $event"
            >
                <template v-slot:item.gender="{ item, index }">
                    {{ item.gender === 'M' ? '남성' : (item.gender === 'F' ? '여성' : '') }}
                </template>
                <template v-slot:item.resend="{ item, index }">
	                <v-btn v-if="item.result" color="kb-primary-color" @click="openResend(item)">재발송</v-btn>
                </template>
                <template v-slot:item.sent="{ item, index }">
                    {{ item.sent ? `발송완료(${item.sent})` : ''}}
                </template>
                <template v-slot:item.result="{ item, index }">
                    {{ item.result ? list[item.result].name : '' }}
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <div class="text-center pt-2 mt-5">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <div class="pl-5" style="width:100px;">
                <v-select label="표시갯수"
                    v-model="itemsPerPage"
                    :items="[10,15,20,30,50,100,200,1000]"
                />
            </div>
            <v-spacer></v-spacer>
        </v-card-actions>


        <v-dialog v-model="showMailDialog" width="600" style="overflow-x:hidden">
            <v-card>
                <v-card-title>
	                결과 메일 재발송
                </v-card-title>
                <v-card-text>
                    <v-layout justify-center column>
                        <v-row no-gutters>
                            <v-col cols="3" class="mt-5">
                                <v-subheader>이메일 주소</v-subheader>
                            </v-col>
                            <v-col cols="9" align-self="center" class="mt-5">
                                <v-text-field v-model="email" hide-details dense outlined></v-text-field>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click.native="send()">발송</v-btn>
                    <v-btn @click.native="showMailDialog=false">취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
    import domtoimage from 'dom-to-image'

    import Buttons from "./Buttons";
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    am4core.useTheme(am4themes_animated);

    export default {
    	components: {
            domtoimage,
    		Buttons
        },
	    data() {
		    return {
		    	page: 1,
                pageCount: 0,
			    itemsPerPage: 100,
                training: {},
			    trainees: [],
			    headers: [
                    {text: '#', sortable: true, value: 'id'},
				    {text: '이름', sortable: true, value: 'name'},
				    {text: '직원번호', sortable: true, value: 'id_number' },
				    {text: '직급', sortable: true, value: 'position' },
				    {text: '소속', sortable: true, value: 'division' },
				    {text: '성별', sortable: true, value: 'gender' },
				    {text: 'E-mail (ID)', sortable: true, value: 'email' },
				    {text: '과정 결과', sortable: true, value: 'result' },
				    {text: '결과 메일 재발송', sortable: false, value: 'resend'},
				    {text: '결과 메일 발송 여부', sortable: true, value: 'sent' },
			    ],
                isLoading: true,
                selected: [],
                search: '',
                showDialog: false,
                file: null,
                chart: null,
                total: 0,
                completed: 0,
                incompleted: 0,

                showMailDialog: false,
			    item: null,
                email: '',

                list: {
                    'ISTJ': {name: '오차없는 메트로놈', count: 0},
                    'ISFJ': {name: '우직한 주춧돌', count: 0},
                    'INFJ': {name: '우주를 담은 수정구슬', count: 0},
                    'INTJ': {name: '혼자서도 잘하는 단호박', count: 0},
                    'ISTP': {name: '칠전팔기 레이서', count: 0},
                    'ISFP': {name: '겸손한 마시멜로', count: 0},
                    'INFP': {name: '수줍은 불꽃', count: 0},
                    'INTP': {name: '산골짜기 문학소년', count: 0},
                    'ESTP': {name: '모험을 떠나는 콜럼버스', count: 0},
                    'ESFP': {name: '명랑한 행복 배달부', count: 0},
                    'ENFP': {name: '호기심 많은 앵무새', count: 0},
                    'ENTP': {name: '다재다능한 에너지몬스터', count: 0},
                    'ESTJ': {name: '흐트러짐 없는 일잘러', count: 0},
                    'ESFJ': {name: '따스한 멘탈 충전소', count: 0},
                    'ENFJ': {name: '꺼지지 않는 응원봉', count: 0},
                    'ENTJ': {name: '악단을 이끄는 마에스트로', count: 0},
                }
            }
        },
        watch: {
            showDialog() {
                this.file = null
            }
        },
        mounted() {

            // this.chart.legend = new am4charts.Legend()


	    	this.training.id = this.$route.params.id

            this.getList()
        },
        methods: {
            getList() {
                this.isLoading = true
                const params = {
                    id: this.training.id
                }
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/results`, {params: params}).then(response => {
                    if (response.data.code === '0000') {
                        this.training = response.data.result
                        this.isLoading = false

                        const trainees = []
                        this.training.trainees.forEach(trainee => {
                            if (trainee.mails && trainee.mails.length > 0) {
                                trainee.mails.forEach(mail => {
                                    if (mail.status === 'sent') {
                                        trainee.sent = mail.sent_at
                                    }
                                    if (mail.status === 'scheduled') {
                                        trainee.scheduled = mail.scheduled
                                    }
                                })


                            }
                            trainees.push(trainee)
                        })

                        this.trainees = trainees

                        this.selected = this.trainees

                        this.total = this.training.trainees.length

                        this.training.trainees.forEach(trainee => {
                        	if (!trainee.result) {
                        	    this.incompleted++
                        	    return
                            }

                        	this.completed++

                            this.list[trainee.result].count++
                        })

	                    const chartData = []
	                    Object.keys(this.list).forEach(key => {
	                        chartData.push(this.list[key])
                        })

	                    // this.chart.data = chartData
	                    this.drawChart(chartData)
                    }
                })
            },
	        drawChart(chartData) {

                let min = 0, max = 0, total = 0, cnt = 0
                chartData.forEach(data => {
                    if (min === -1 || (min > 0 && min > data.count)) {
                	    min = data.count
                    }
                	if (max < data.count) {
                	    max = data.count
                    }

                	total += data.count
                    cnt++
                })
                let avg = total / cnt

                const chart = am4core.create("chart", am4charts.XYChart);
                chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                chart.padding(40, 40, 40, 40);

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.dataFields.category = "name";
                categoryAxis.renderer.minGridDistance = 60;
                categoryAxis.fontSize = 11;
                categoryAxis.renderer.labels.template.rotation = -30;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.max = max;
                valueAxis.strictMinMax = true;
                valueAxis.renderer.minGridDistance = 30;
                valueAxis.fontSize = 11;

                // axis break
                if (max > avg * 5 && max - avg > 10) {

                    let axisBreak = valueAxis.axisBreaks.create();
                    axisBreak.startValue = Math.round(max - (max - avg) * 9 / 10) ;
                    axisBreak.endValue = Math.round(max - (max - avg) / 10)
                    //axisBreak.breakSize = 0.005;

                    // fixed axis break
                    let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min);
                    axisBreak.breakSize = 0.05 * (1 - d) / d; // 0.05 means that the break will take 5% of the total value axis height

                    // make break expand on hover
                    let hoverState = axisBreak.states.create("hover");
                    hoverState.properties.breakSize = 1;
                    hoverState.properties.opacity = 0.1;
                    hoverState.transitionDuration = 1500;

                    axisBreak.defaultState.transitionDuration = 1000;
                }

                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.categoryX = "name";
                series.dataFields.valueY = "count";
                series.columns.template.tooltipText = "{valueY.value}";
                series.columns.template.tooltipY = 0;
                series.columns.template.strokeOpacity = 0;

                let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.verticalCenter = "bottom";
                labelBullet.label.dy = 15;
                labelBullet.label.fontSize = 10;
                labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

                // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                series.columns.template.adapter.add("fill", function(fill, target) {
                    return chart.colors.getIndex(target.dataItem.index);
                });

                chart.data = chartData

            },
            editItem(item, index) {
                this.$router.push(`/training/${this.training.id}/trainee/${item.id}/form`)
            },
            deleteAll() {
                const self = this

                if (!this.selected.length) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 교육생이 없습니다.',
                    })
                    return
                }
                this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `선택된 모든 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{


                        const ids = []
                        this.selected.forEach(item => {
                            ids.push(item.id)
                        })

                        self.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },
	    	deleteItem(item, index) {
                const self = this

	    		this.$store.commit('showDialog', {
	    			type: 'confirm',
                    message: `[${item.name}] 교육생을 삭제하시겠습니까?`,
                    okCb: _ =>{

                        const ids = [ item.id ]

                        self.axios.delete(`${process.env.VUE_APP_SERVER_HOST}/api/admin/trainee/${JSON.stringify(ids)}`).then(response => {
                            if (response.data.code === '0000') {
                                self.getList()
                            }
                        })
                    }
                })
            },

            moveTo(path) {
	    		this.$eventBus.$emit('MOVE_TO', path)
            },

            downloadTemplate() {
                location.href=`${process.env.VUE_APP_SERVER_HOST}/download/template`
            },
            upload() {
                const self = this

                if (!this.file) {
                    this.$store.commit('showDialog', {
                        type: 'alert',
                        message: '선택된 파일이 없습니다.'
                    })
                    return
                }

                const formData = new FormData()
                formData.append('file', this.file)
                formData.append('id', this.training.id)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`, formData, options).then(response => {
                    self.showDialog = false
                    self.getList()
                })
            },

            downloadResultChart() {
                const self = this
                domtoimage.toJpeg(document.getElementById('chartHolder'), { quality: 0.95 })
                        .then(function (dataUrl) {
                            const link = document.createElement('a');
                            link.download = `${self.training.name}_결과차트_${moment().format('YYYY-MM-DD')}.jpeg`;
                            link.href = dataUrl;
                            link.click();
                        });

            },
            downloadResultExcel() {
                this.axios({
                    url: `${process.env.VUE_APP_SERVER_HOST}/api/admin/result/excel/${this.training.id}`,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.training.name}_결과${moment().format('YYYY-MM-DD')}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            openResend(item) {
            	this.item = item
	            this.email = item.email
                this.showMailDialog = true
            },

            send() {
                const params = {
                    id: this.item.id,
                    training_id: this.training.id,
	                email: this.email,
                }

                this.isLoading = true
                this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/result/send`, params).then(response => {
                    if (response.data.code === '0000') {
                        this.isLoading = false
                        this.getList()

                        this.showMailDialog = false

                        let message = '결과 메일을 재발송했습니다.'

                        this.$store.commit('showSnackbar', {
                            message: message,
                            color: 'success',
                            duration: 3000,
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chart { width: 100%; height: 30vw; }
    .list_title { height: 64px;}

    #chartHolder {
        background-color: #ffffff;
    }

    .legend { margin-top: -30px; width: 98%; background-color: #ffffff; height: 30px;}
</style>
