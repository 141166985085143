<template>
    <v-card width="100%">
        <v-card-title>효과음 관리</v-card-title>
        <v-card-actions>
            <v-select :items="trainings"
                      item-value="id" item-text="name"
                      v-model="currentTraining"
                      outlined
                      return-object
                      label="교육과정"
                      @change="$router.push(`/manage/audio/${currentTraining.id}`)"
            />
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
	        <v-layout column>
                <v-card elevation="2" width="100%" class="mb-10">
                    <v-card-title>
                        배경음
                    </v-card-title>
                    <v-card-text>
                        <v-layout
		                        column
		                        class="mb-3"
                                v-for="(audio, index) in audios"
                                v-if="audio.type.startsWith('day')"
                                :key="index"
                        >
                            <label class="font-weight-bold">{{ audio.label }}</label>
                            <v-layout style="align-self: start; width: 100%">
                                <v-switch
		                                style="width: 200px"
                                        v-model="audio.enabled"
                                        dense
                                        inset
                                        color="blue darken-2"
                                        :label="`${audio.enabled ? '사용함' : '사용안함'}`"
		                                @change="changeEnabled(audio)"
                                ></v-switch>
                                <v-file-input class="file-input" v-model="audioFile" hide-details hide-input prepend-icon="mdi-music" accept=".mp3,.wav,.ogg" @click="onClickFileInput(audio)" :disabled="!audio.enabled" />
                                <v-text-field v-model="audio.name" style="width: 100%;" class="mr-10" readonly @click="onFocus(index)" :disabled="!audio.enabled" />
                                <audio controls :src="audio.enabled && `${audio.path}`" style="width: 500px" />
                            </v-layout>
                        </v-layout>
                    </v-card-text>
                </v-card>
                <v-card elevation="2" width="100%">
                    <v-card-title>
	                    효과음
                    </v-card-title>
                    <v-card-text>
                        <v-layout
                                column
                                class="mb-3"
                                v-for="(audio, index) in audios"
                                v-if="!audio.type.startsWith('day')"
                                :key="index"
                        >
                            <label class="font-we">{{ audio.label }}</label>
                            <v-layout style="align-self: start; width: 100%">
                                <v-switch
                                        style="width: 200px"
                                        v-model="audio.enabled"
                                        dense
                                        inset
                                        color="blue darken-2"
                                        :label="`${audio.enabled ? '사용함' : '사용안함'}`"
                                        @change="changeEnabled(audio)"
                                ></v-switch>
                                <v-file-input class="file-input" v-model="audioFile" hide-details hide-input prepend-icon="mdi-music" accept=".mp3,.wav,.ogg" @click="onClickFileInput(audio)" :disabled="!audio.enabled" />
                                <v-text-field v-model="audio.name" style="width: 100%;" class="mr-10" readonly @click="onFocus(index)" :disabled="!audio.enabled" />
                                <audio controls :src="audio.enabled && `${audio.path}`" style="width: 500px" />
                            </v-layout>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
	data() {
		return {
			trainings: [],
            currentTraining: {},
			audios: [],
            apiUrl: process.env.VUE_APP_SERVER_HOST,
            audioFile: null,
            currentAudio: null,
		}
	},
    watch: {
		$route(to, from) {
			const id = to.params.id
            this.trainings.forEach(training => {
            	if (training.id === id) {
            		this.currentTraining = training
                }
            })
            this.getList()
        },
        audioFile(val) {
			const self = this
			if (val) {
                const formData = new FormData()
                formData.append('file', val)
                formData.append('training_id', this.currentTraining.id)
                formData.append('id', this.currentAudio.id)

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                this.axios.post(`${this.apiUrl}/api/admin/audio`, formData, options).then(response => {
                    self.audioFile = null
                	self.getList();
                })
            }
        }

    },
	mounted() {
        this.currentTraining.id = this.$route.params.id || this.$cookie.get('training_id')
		this.getTrainings()
	},
    methods: {
	    onFocus(index) {
	    	$('.file-input').eq(index).find('input').click()
        },
        onClickFileInput(audio) {
            this.currentAudio = audio
	        this.audioFile = null
        },
		getTrainings() {
			this.axios.get(`${this.apiUrl}/api/admin/training`).then(response => {
				if (response.data.code === '0000') {
					this.trainings = response.data.result

                    if (this.trainings.length > 0) {
                    	if (this.currentTraining.id) {
                    		this.trainings.forEach(training => {
                    			if (training.id == this.currentTraining.id) {
                    				this.currentTraining = training
                                }
                            })
                        } else {
		                    this.currentTraining = this.trainings[0]
	                    }
                    }
					this.getList()
				}
			})
		},
		getList() {
		    const self = this
			const params = {
				'training_id': this.currentTraining.id,
            }
			this.axios.get(`${this.apiUrl}/api/admin/audio`, {params: params}).then(response => {
				if (response.data.code === '0000') {
				    self.audios = []
					Object.keys(response.data.result).forEach((key, index) => {
						self.audios.push(response.data.result[key])
                    })
                }
			})
		},
	    changeEnabled(audio) {
	        const self = this

	        const params = {
	        	id: audio.id,
		        training_id: this.currentTraining.id,
                enabled: audio.enabled,
            }
            this.axios.post(`${this.apiUrl}/api/admin/audio/enabled`, params).then(response => {
                self.getList();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .day {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #FF773B;
        border-radius: 10px;
        min-height: 264px;

        .label {
            padding-top: 30px;
            display: flex;
            writing-mode: vertical-lr;
            text-orientation: upright;
            align-items: center;
            font-weight: 600;
        }
    }

    .item {
        position: relative;
        width: 144px;
        height: 256px;
        background: #efefef;
        cursor: pointer;
        border-radius: 10px;

        .btn-delete {
            position: absolute;
            right: 5px;
            top: 5px;
            font-weight: 600;
            background: rgba(255,255,255, 0.5)
        }

        .no-img {
            color: #ddd;
            font-size: 100px !important;
        }

        &.add-item {
        }

        .item-label {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to top, rgba(0,0,0,.7) 0%, rgba(0,0,0,.6) 25%, rgba(0,0,0,.5) 50%, rgba(0,0,0,.3) 70%, rgba(0,0,0,0) 100%);
            padding: 50px 10px 10px;
            color: #ffffff;
            text-align: center;
            font-size: 20px;

            .item-type {
                font-size: 13px;
                color: #efefef;
            }
        }
    }
</style>
