<template>
<!--
    <div class="btn-group" role="group">
        <button class="btn btn-secondary" @click="moveTo(`/training/${training.id}/trainees`)">교육생 정보</button>
        <button class="btn" @click="moveTo(`/training/${training.id}/results`)">과정 결과</button>
    </div>

-->
    <ul class="nav nav-pills mb-3" role="tablist">
        <li class="nav-item mr-1" v-for="(button, index) in buttons">
            <a class="nav-link"
                :class="$route.path.endsWith(button.link) ? 'active' : ''"
                data-toggle="pill"
                role="tab"
                aria-selected="true"
                @click="moveTo(button.link)"
            >
                {{ button.name }}
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        data() {
            return {
                buttons: [
                    {
                        name: '교육생 정보',
                        link: 'trainees',
                    },
                    {
                        name: '과정 결과',
                        link: 'results',
                    }
                ]
            }
        },
    	props: [
    		'training',
        ],
	    methods: {
		    moveTo(path) {
                if (!this.$route.path.endsWith(path)) {
                    this.$eventBus.$emit('MOVE_TO', path)
                }
		    }
	    },
    }
</script>

<style lang="scss" scoped>
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #60584C !important;
    }
    .nav-pills .nav-link {
        background-color: #a79985 !important;
        color: #ffffff;
    }
</style>