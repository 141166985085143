<template>
    <v-card width="100%" class="form">
        <v-card-title>{{ training.id ? '교육과정 편집' : '새 교육 생성' }}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-layout justify-center column>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>교육 과정명</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="training.name"
                                    outlined
                                    dense
                                    required
                                    :rules="[v => !!v || '필수항목입니다.']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>진행일</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                    v-model="training.date"
                                    type="date"
                                    outlined
                                    dense
                                    required
                                    :rules="[v => !!v || '필수항목입니다.']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <v-subheader>진행 시간</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-layout align-center justify-center>
                                <v-text-field
                                        v-model="training.start_time"
                                        type="time"
                                        outlined
                                        dense
                                        required
                                        :rules="[v => !!v || '필수항목입니다.']"
                                        @change="onChangeStartTime()"
                                ></v-text-field>
                                <p style="font-size: 20px; padding: 0 10px;"> ~ </p>
                                <v-text-field
                                        v-model="training.end_time"
                                        type="time"
                                        outlined
                                        dense
                                        required
                                        :rules="[v => !!v || '필수항목입니다.']"
                                ></v-text-field>
                            </v-layout>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-primary mr-2" @click="$router.back()">뒤로</button>
            <button class="btn btn-secondary" @click="save()">완료</button>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default  {
	    data() {
		    return {
		    	training: {
                },
                valid: false,
		    }
	    },
    	mounted() {
	    	const id = this.$route.params.id;

	    	if (id) {
			    this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training/${id}`).then(response => {
				    if (response.data.code === '0000') {
					    this.training = response.data.result

                        $('.form').show();
				    }
			    })
		    } else {
			    $('.form').show();
		    }
	    },
	    methods: {
            onChangeStartTime() {
                if (this.training.start_time && !this.training.end_time) {
                    this.training.end_time = this.training.start_time
                }
            },
		    save() {
		    	if (!this.$refs.form.validate()) return;

			    this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/admin/training`, this.training).then(response => {
				    if (response.data.code === '0000') {
				    	this.$router.replace('/training')
				    }
			    })
		    }
	    },
    }
</script>

<style lang="scss" scoped>
    .form { display: none}
</style>
